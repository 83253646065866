
export function urlPathFromRelativeDirectoryAndName(relativeDirectory, name) {
  var urlPath = `/${name}`
  if (relativeDirectory !== "") {
    urlPath = `/${relativeDirectory}${urlPath}`
  }
  return urlPath
}

export function convertArticleNodeToArticleProp(node) {
  const { frontmatter, fields, parent } = node
  const {
    title,
    subtitle,
    displayDate,
    featuredImage,
  } = frontmatter
  const urlPath = urlPathFromRelativeDirectoryAndName(parent.relativeDirectory, parent.name)
  const readingTimeMinutes = fields.readingTime.minutes

  return {
    urlPath,
    title,
    subtitle,
    displayDate,
    featuredImage,
    readingTimeMinutes,
  };
}
