import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const ArticleLink = ({ article, isFeatured }) => (
  <div className={`article ${ isFeatured ? "featured" : ""}`} key={article.urlPath}>
    <div className="articleWrap">
      <div className="articlePhoto">
        {article.featuredImage && (<Link to={article.urlPath}>
          <GatsbyImage
            image={isFeatured ? article.featuredImage.childImageSharp.headlineImage : article.featuredImage.childImageSharp.thumbnailImage}
          />
        </Link>
        )}
      </div>

      <div className="articleDeets">
        <Link to={article.urlPath}>
          <h2 className="articleTitle">{article.title}</h2>
          <h3 className="subtitle">{article.subtitle}</h3>
        </Link>

        <span className="attribution">{Math.ceil(article.readingTimeMinutes)} minute read</span>
      </div>
    </div>
  </div>
)

ArticleLink.propTypes = {
  article: PropTypes.shape({
    urlPath: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    featuredImage: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        thumbnailImage: PropTypes.object,  // TODO: this has a specific shape, just not in our control
        headlineImage: PropTypes.object,  // TODO: this has a specific shape, just not in our control
      }),
    }),
    readingTimeMinutes: PropTypes.number.isRequired,
  }),
  isFeatured: PropTypes.bool,
}

export default ArticleLink;
