import React from 'react'

export default function InstagramCTA() {
  return (
    <section className="stripe stripe--alt stripe--centered" id="instagramCTA">
      <div className="container">
        <a href="https://instagram.com/harperdogapp" target="_blank" className="instagramCTA" rel="noreferrer">
          <span className="sectionTitle">Meet our #harperdogs</span>
          <span className="icon icon--instagram"></span>
        </a>
      </div>
    </section>
  )
}
