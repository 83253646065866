import React from "react"
import PropTypes from "prop-types"
import ArticleLink from '@components/article-link'

const ArticleLinkList = ({ articles }) => articles.map((article) => (
  <ArticleLink key={article.urlPath} article={article} />
));

ArticleLinkList.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      urlPath: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string,
      displayDate: PropTypes.string.isRequired,
      featuredImage: PropTypes.shape({
        childImageSharp: PropTypes.shape({
          thumbnailImage: PropTypes.object,  // TODO: this has a specific shape, just not in our control
          headlineImage: PropTypes.object,  // TODO: this has a specific shape, just not in our control
        }),
      }),
      readingTimeMinutes: PropTypes.number.isRequired,
    }),
  ),
}

export default ArticleLinkList;
