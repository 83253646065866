import { graphql } from "gatsby"
import React from "react"
import PropTypes from "prop-types"

import ArticleLayout from "@components/article-layout"
import ArticleLink from '@components/article-link'
import ArticleLinkList from '@components/article-link-list'
import Salutation from "@components/salutation"
import SEO from "@components/seo"
import { convertArticleNodeToArticleProp } from '@lib/util'

const TopicIndexPage = ({
  data,
  pageContext,
}) => {
  const { topic, urlPath } = pageContext;
  const featuredArticles = data.featuredArticles.edges.map((edge) => convertArticleNodeToArticleProp(edge.node));
  const articles = data.articles.edges.map((edge) => convertArticleNodeToArticleProp(edge.node));
  return (
    <ArticleLayout>
      <SEO
        title={`${topic} Archive — Articles`}
        pathname={urlPath}
      />

      <main className='topic-index'>
        <div className='container'>
          <div className="articlesListHeader">
            <h1 className="sectionTitle">{topic}</h1>
          </div>
        </div>
  
        {featuredArticles.length > 0 && (
          <ArticleLink
            isFeatured={true}
            article={featuredArticles[0]}
          />
        )}

        <div className='container'>
          <div className="articlesListHeader">
            <h2 className="listTitle">The Latest</h2>
          </div>
        </div>
          
        <ArticleLinkList
          articles={articles}
        />
      </main>
      <Salutation />
    </ArticleLayout>
  );
};

export default TopicIndexPage;

TopicIndexPage.propTypes = {
  data: PropTypes.shape({
    articles: PropTypes.arrayOf(PropTypes.object),
    featuredArticles: PropTypes.arrayOf(PropTypes.object),
  }),
  pageContext: PropTypes.shape({
    topic: PropTypes.string.isRequired,
    urlPath: PropTypes.string.isRequired,
  })
};

export const pageQuery = graphql`
  query($topic: String!) {
    articles: allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "articles/.*[.]md$/"},
        frontmatter: {
          topics: {in: [$topic]},
          isFeaturedOnTopic: {ne: true}
        },
      },
      sort: {
        fields: frontmatter___date, 
        order: DESC,
      },
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            subtitle
            displayDate: date(formatString: "MMM D, YYYY")
            featuredImage {
              childImageSharp {
                thumbnailImage: gatsbyImageData(width: 300)
              }
            }
          }
          fields {
            readingTime {
              minutes
            }
          }
          parent {
            ... on File {
              name
              relativeDirectory
            }
          }
        }
      }
    }
    featuredArticles: allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "articles/.*[.]md$/"},
        frontmatter: {
          topics: {in: [$topic]},
          isFeaturedOnTopic: {eq: true}
        }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            subtitle
            displayDate: date(formatString: "MMM D, YYYY")
            featuredImage {
              childImageSharp {
                headlineImage: gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          fields {
            readingTime {
              minutes
            }
          }
          parent {
            ... on File {
              name
              relativeDirectory
            }
          }
        }
      }
    }
  }
`;
