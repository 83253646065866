import React from "react"
import PropTypes from "prop-types"

import BodyClass from "./body-class"
import Header from "./header"
import Footer from "./footer"
import InstagramCTA from "@components/instagram-cta"
import AppStoreLink from '@components/app-store-link'
import AppleIcon from '@components/apple-icon'
import "./layout.scss"

const ArticleLayout = ({
  children,
  forceInApp,
}) => {
  return (
    <BodyClass
      bodyClassName={"articles headerIsFixed"}
      forceInApp={forceInApp || false}
    >
      <Header showNavigation={false} className='articles-header is-fixed'>
        {' '}
        <a href="/articles" className='after-logo'>Articles</a>

        <div className="u-right">
          <AppStoreLink id="articlesHeaderCTA">
            <AppleIcon />
            <span>Try for free</span>
          </AppStoreLink>
        </div>
      </Header>

      <main>
        {children}
      </main>
      
      <InstagramCTA />

      <Footer />

    </BodyClass>
  )
}

ArticleLayout.propTypes = {
  children: PropTypes.node.isRequired,
  forceInApp: PropTypes.bool,
}

export default ArticleLayout
